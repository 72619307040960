import React, { useState } from 'react';
import './Menu.css';
import logo from '../../content/logo/logo_opacity.png';
import { Col, Container, Row } from 'react-bootstrap';
import { RiUserSettingsLine } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../App';
import SearchTiny from '../search/SearchTiny';

const Menu: React.FC = () => {
    const { user } = useAuthContext();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const onSearch = (searchText: string) => {
        window.location.href = `/search/result?keywords=${searchText}`;
    }

    const userInitials = `${user.personName ? user.personName.charAt(0) : ''}${user.personLastName ? user.personLastName.charAt(0) : ''}`

    return (
        <>
            <div className="navbar-wrapper">
                <Container fluid>
                    <Row className="align-items-center justify-content-center">
                        <Col xs="auto">
                            <Link to="/">
                                <img src={logo} alt="Logo" className='menu-logo-image' />
                            </Link>
                        </Col>
                        <Col>
                            <SearchTiny onSearch={onSearch} />
                        </Col>
                        <Col xs="auto">
                            <div className="d-flex justify-content-end align-items-center" style={{ height: "100%" }}>
                                <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                                    <GiHamburgerMenu />
                                </div>
                                {user?.personId !== undefined && (
                                    <>
                                        <Link to="/dashboard" className="navbar-item">Dashboard</Link>
                                        <Link to="/meetings" className="navbar-item">Meetings</Link>
                                    </>
                                )}
                                <Link to="/news" className="navbar-item">Blog</Link>
                                <Link to="/pricing" className="navbar-item">Pricing</Link>
                                <Link to="/about-us" className="navbar-item">About us</Link>
                                {user?.personId === undefined ? (
                                    <Link to="/signin" className="navbar-item">Sign in</Link>
                                ) : (
                                    <Link to="/profile" className="navbar-item profile-settings">
                                        {userInitials.length > 0 ? 
                                            <span style={{fontWeight: '600'}}>{userInitials}</span> : 
                                            <RiUserSettingsLine />
                                        }
                                    </Link>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {mobileMenuOpen && (
                <div className="mobile-menu" onClick={toggleMobileMenu}>
                    <div className="close-menu" onClick={toggleMobileMenu}>×</div>
                    {user?.personId !== undefined && (
                        <>
                            <Link to="/dashboard" className="navbar-item" onClick={toggleMobileMenu}>Dashboard</Link>
                            <Link to="/meetings" className="navbar-item" onClick={toggleMobileMenu}>Meetings</Link>
                        </>
                    )}
                    <Link to="/news" className="navbar-item" onClick={toggleMobileMenu}>Blog</Link>
                    <Link to="/pricing" className="navbar-item" onClick={toggleMobileMenu}>Pricing</Link>
                    <Link to="/about-us" className="navbar-item" onClick={toggleMobileMenu}>About us</Link>
                    {user?.personId === undefined ? (
                        <Link to="/signin" className="navbar-item" onClick={toggleMobileMenu}>Sign in</Link>
                    ) : (
                        <Link to="/profile" className="navbar-item profile-settings" onClick={toggleMobileMenu}><RiUserSettingsLine /></Link>
                    )}
                </div>
            )}
        </>
    );
};

export default Menu;
