import React from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../../../Main.css';

const PendingAllGoodRequestorRescheduleForm: React.FC = () => {
    return (
        <Container>
            <Row style={{marginTop: '20px'}}>
              <h4>Unfortunately meeting has been decline.</h4>  
            </Row>
            <Row style={{marginTop: '20px'}}>
              <p>
                 We contacted Introductor and soon you will be notified about new times.
              </p>
            </Row>
        </Container>
    );
}

export default PendingAllGoodRequestorRescheduleForm;
