import React, {useState, useEffect} from 'react';
import {getPersonScheduledMeetings} from '../../api/personApi';
import { useNavigate } from 'react-router-dom';
import './MeetingPage.css';
import { getUIText } from '../../types/meetingStatus';
import '../../Main.css';
import { useAuthContext } from '../../App';
import { Col, Container, Row } from 'react-bootstrap';
import StyledTable from '../molecules/table/styledtable/StyledTable';
import StyledButton from '../atoms/button/StyledButton';
import { DateUtils } from '../../utils/DateUtils';

const MeetingList: React.FC = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState<boolean>(true);
    const [dashboardTableData, setDashboardTableData] = useState<string[][]>([]);
    const [tableRowIds, setTableRowIds] = useState<string[]>([]);
    const MEETING_LIST_TABLE_HEADERS = ['Meeting Target', 'Company', 'Title', 'Date Time', 'Requestor', 'Introductor', 'Meeting Status'];
  
    useEffect(() => {
        loadMeetings();
    }, [user.personId, isPending]);

    const loadMeetings = () => {
        if (user.personId !== undefined) {
            if (isPending) {
                getPersonScheduledMeetings(user.personId)
                    .then(response => {
                        const tableData: React.SetStateAction<string[][]> = [];
                        const ids: React.SetStateAction<string[]>  = [];

                        response.data.map((meeting, index) => {
                            const meetingRow = [
                                meeting.expert ? meeting.expert.name + ' ' + meeting.expert.lastName : '-',
                                meeting.expert ? (meeting.expert.occupationList ? (meeting.expert.occupationList.length > 0 ? meeting.expert.occupationList[0].companyName : '-') : '-') : '-',
                                meeting.expert ? (meeting.expert.occupationList ? (meeting.expert.occupationList.length > 0 ? meeting.expert.occupationList[0].title : '-') : '-') : '-',
                                meeting.meetingDateTime? DateUtils.formatReadableDateTime(meeting.meetingDateTime) : '-',
                                meeting.interessant ? meeting.interessant.name + ' ' + meeting.interessant.lastName : '-',
                                meeting.introductor ? meeting.introductor.name + ' ' + meeting.introductor.lastName : '-',
                                meeting.meetingSubstatus ? getUIText(meeting.meetingSubstatus) : '-'
                        ];
                            tableData.push(meetingRow);
                            ids.push(meeting.meetingId ? meeting.meetingId : '');
                        })

                        setDashboardTableData(tableData);
                        setTableRowIds(ids);
                    })
            } else {

            }
        }
    }

    function clickMeetingRow(meetingId: string): void {
        navigate(`/meetings/${meetingId}`);
    }

    return (
        <>
            <Container fluid style={{ minHeight: "calc(100vh - 100px)", maxWidth: '90%' }}>
                <Row style={{ paddingTop: "40px"}}>
                    <Col style={{maxWidth: "190px"}}><StyledButton label={'Pending Meetings'} variant={isPending ? 'secondary' : 'third'} onClick={() => setIsPending(true)}/></Col>
                    <Col style={{maxWidth: "190px"}}><StyledButton label={'Successful Meetings'} variant={!isPending ? 'secondary' : 'third'} onClick={() => setIsPending(false)}/></Col>
                </Row>
                <Row style={{ paddingTop: "30px"}}>
                    <h5>{'Requested Meetings'}</h5>
                </Row>
                <Row style={{ paddingTop: "20px"}}>
                    <StyledTable 
                            tableHeaders={MEETING_LIST_TABLE_HEADERS} 
                            tableData={dashboardTableData} 
                            tableRowsIds={tableRowIds}
                            onClickRow={clickMeetingRow}
                            lastColumnColored={true}
                        /> 
                </Row>
            </Container>          
        </>
    );
}

export default MeetingList;
