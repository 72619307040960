import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ProcessSteps.css';
import '../../../Main.css';

const ProcessSteps: React.FC = () => {

  return (
    <Container>
      <Row className='process-steps-wrapper'>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>01</h3>
            </Row>
            <Row>
                <h4>Create your Profile</h4>
            </Row>
            <Row>
                <p>Join Introductable and create a profile that details your career, network and industry expertise.</p>
            </Row>
        </Col>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>02</h3>
            </Row>
            <Row>
                <h4>Select Meeting Target</h4>
            </Row>
            <Row>
                <p>Search on the platform for your meeting targets.</p>
            </Row>
        </Col>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>03</h3>
            </Row>
            <Row>
                <h4>Secure Meetings</h4>
            </Row>
            <Row>
                <p>Provide suggested times, reasons and purpose of the meeting.</p>
            </Row>
        </Col>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>04</h3>
            </Row>
            <Row>
                <h4>Agree on meeting date and time</h4>
            </Row>
            <Row>
                <p>Working together between Requestor and Introductor agree on time with Meeting Target.</p>
            </Row>
        </Col>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>05</h3>
            </Row>
            <Row>
                <h4>Have Your Meeting</h4>
            </Row>
            <Row>
                <p>Your meeting will happen on Google Meetings.</p>
            </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ProcessSteps;
