import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Row } from 'react-bootstrap';
import { TimeProposal } from '../../../types/meeting';
import './TimeProposalComponent.css';
import '../../../Main.css';
import StyledSelect from '../../atoms/select/StyledSelect';
import { generateTimeOptions } from '../../../utils/DateUtils';
import { RiDeleteBinLine } from "react-icons/ri";
import StyledCalendarSelect from '../../atoms/calendar/StyledCalendarSelect';


const DEFAULT_TIME_PROPOSALS = 5;

type TimeProposalInput = {
    date: string;
    startTime: string;
    endTime: string;
};

type TimeProposalComponentProps = {
    maxProposals?: number;
    timeProposalsProp?: TimeProposal[];
    onTimeProposalsChange: (timeProposals: TimeProposal[]) => void;
    title: React.ReactNode;
    timeFromOnly?: boolean;
};

const TimeProposalComponent: React.FC<TimeProposalComponentProps> = ({ maxProposals = DEFAULT_TIME_PROPOSALS, onTimeProposalsChange, title, timeFromOnly = false }) => {
    const [timeProposals, setTimeProposals] = useState<TimeProposalInput[]>([{ date: '', startTime: '', endTime: '' }]);
    const timeOptions = generateTimeOptions();

    const handleAddRow = () => {
        if (timeProposals.length < maxProposals) {
            setTimeProposals(current => [...current, { date: '', startTime: '', endTime: '' }]);
        }
    };

    const handleRemoveRow = (index: number) => {
        setTimeProposals(current => current.filter((_, i) => i !== index));
    };

    useEffect(() => {
        const formattedProposals = timeProposals.map(proposal => ({
            proposedStartTime: `${proposal.date}T${proposal.startTime}:00.000Z`,
            proposedEndTime: `${proposal.date}T${proposal.endTime}:00.000Z`,
        }));
        onTimeProposalsChange(formattedProposals);
    }, [timeProposals, onTimeProposalsChange]);

    const handleChange = (index: number, field: 'startTime' | 'endTime' , event: React.ChangeEvent<HTMLSelectElement>) => {
        const {value, selectedIndex}  = event.target;
        if(timeFromOnly && field === 'startTime') {
            setTimeProposals(current =>
                current.map((proposal, i) => (i === index ? { ...proposal, [field]: value, endTime: timeOptions[selectedIndex - 1]?.nextOption?.value || timeOptions[0].value } : proposal)),
            );
        } else {
            setTimeProposals(current =>
                current.map((proposal, i) => (i === index ? { ...proposal, [field]: value } : proposal)),
            );
        }
    };

    const handleDateChange = (value: string, index: number, field: 'date') => {
        setTimeProposals(current =>
            current.map((proposal, i) => (i === index ? { ...proposal, [field]: value } : proposal)),
        );
    };

    return (
        <>
        <Container>
            <Row>
                {title}
            </Row>
            {timeProposals.map((proposal, index) => (
            <Form.Group key={index} className="time-proposal-inputs">
                <StyledCalendarSelect 
                    value={proposal.date}
                    onChange={(value: string) => handleDateChange(value, index, 'date')}
                />
                
                <StyledSelect 
                    name={'startTime'} 
                    value={proposal.startTime} 
                    onChangeSelect={e => handleChange(index, 'startTime', e)} 
                    options={timeOptions.slice(0, -1)}
                    placeholder={'Time From'}                
                />
                {
                    !timeFromOnly && (
                        <StyledSelect 
                        name={'endTime'} 
                        value={proposal.endTime} 
                        onChangeSelect={e => handleChange(index, 'endTime', e)} 
                        options={timeOptions}
                        placeholder={'Time To'}                
                    />)
                }
                {index > 0 && (
                    <Button variant="danger" onClick={() => handleRemoveRow(index)} className="remove-proposal-btn">
                        <RiDeleteBinLine />
                    </Button>
                )}
                </Form.Group>
                ))}
                {
                timeProposals.length <  maxProposals && (
                    <Row onClick={handleAddRow}>
                        <p className='main-color' style={{fontWeight: '600', cursor: 'pointer'}}>+ Add More Times</p>
                    </Row>

                )}
            </Container>
        </> 
    );
};

export default TimeProposalComponent;
