import { Container, Row, Col } from 'react-bootstrap';
import { StyledTableProps } from '../../types';
import './StyledTable.css';
import HorizontalLineSimpleLight from '../../../atoms/lines/HorizontalLineSimpleLight';
import StyledLink from '../../../atoms/link/StyledLink';

const StyledTable: React.FC<StyledTableProps> = ({tableTitle, tableHeaders, tableData, linkUrl, linkTitle, tableRowsIds, selectedRow, onClickRow}) => {
    const noActionNeededList = ['Meeting Confirmed', 'Meeting Proceed', 'Meeting Successful'];
    const actionNeededList = ['Meeting Requested', 'Meeting Date Requested', 'Introductor Added', 'All Meeting Details Added', 'Meeting Paid', 'Meeting Paid New Time Proposals', 'Pay Now'];

    function getStyledColor(value: string): string {
        let returnClass = ''
        if (noActionNeededList.includes(value)) {
            returnClass = 'cell-background-green';
        } else if (actionNeededList.includes(value)) {
            returnClass = 'cell-background-yellow';
        }
        return returnClass;
    }

    return (
        <Container className='styled-table-wrapper'>
            { linkTitle &&
                <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                    <Col>
                        <h5>{tableTitle}</h5>
                    </Col>
                    {linkUrl && linkTitle &&
                        <Col className="d-flex justify-content-end">
                        
                            <StyledLink href={linkUrl} bold={true}>{linkTitle}</StyledLink>

                        </Col>}
                </Row>
            }
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}} className='styled-table-header'>
                {tableHeaders.map((header, index) => (
                    <Col key={index} >{header}</Col>
                ))}
            </Row>
            {tableData.map((row, rowIndex) => (
                <>
                    <HorizontalLineSimpleLight />
                    <Row key={rowIndex} className={`styled-table-row ${selectedRow === rowIndex ? 'styled-table-row-selected' : ''}`}  onClick={() => onClickRow(tableRowsIds[rowIndex], rowIndex)}>
                        {row.map((cellValue, cellIndex) => (
                            <Col className={cellIndex === row.length - 1 ? getStyledColor(cellValue) : ''} key={cellIndex}>{cellValue}</Col>
                        ))}
                    </Row>
                </>
            ))}
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>

            </Row>
        </Container>
    );
}

export default StyledTable;