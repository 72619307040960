import axios, { AxiosResponse } from 'axios';
import { CreatePersonRequest, Person, UpdatePersonRequest } from '../types/person';
import { Meeting } from '../types/meeting';
import { Connection } from '../types/connection';
import { RelationsCalculations } from '../types/calculation';
import { PaymentsRecievedResponse } from '../types/payment';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/person`;

export async function fetchPeople(): Promise<AxiosResponse<Person[]>> {
  return await axios.get(`${API_BASE_URL}`);
}

export async function addPerson(personRequest: CreatePersonRequest): Promise<AxiosResponse<Person>> {
  return await axios.post(`${API_BASE_URL}`, personRequest);
}

export async function updatePerson(id: string, personRequest: UpdatePersonRequest): Promise<AxiosResponse<Person>> {
  return await axios.put(`${API_BASE_URL}/${id}`, personRequest);
}

export async function getPersonById(id: string): Promise<AxiosResponse<Person>> {
  return await axios.get(`${API_BASE_URL}/${id}`);
}

export async function getPersonIntroductors(id: string): Promise<AxiosResponse<Person[]>> {
  return await axios.get(`${API_BASE_URL}/${id}/introductors`);
}

export async function getPersonScheduledMeetings(id: string): Promise<AxiosResponse<Meeting[]>> {
  return await axios.get(`${API_BASE_URL}/${id}/meetings/scheduled`);
}

export async function getPersonInteressantMeetings(id: string): Promise<AxiosResponse<Meeting[]>> {
  return await axios.get(`${API_BASE_URL}/${id}/meetings/interessant`);
}

export async function getPersonIntroductorMeetings(id: string): Promise<AxiosResponse<Meeting[]>> {
  return await axios.get(`${API_BASE_URL}/${id}/meetings/introductor`);
}

export async function getPersonConnections(id: string): Promise<AxiosResponse<Connection[]>> {
  return await axios.get(`${API_BASE_URL}/${id}/connections`);
}

export async function getPersonConnectionsCalculations(id: string): Promise<AxiosResponse<RelationsCalculations>> {
  return await axios.get(`${API_BASE_URL}/${id}/connections/calculations`);
}

export async function getPersonMeetingsRecievedPayments(id: string): Promise<AxiosResponse<PaymentsRecievedResponse>> {
  return await axios.get(`${API_BASE_URL}/${id}/payments/recieved`);
}

