import axios, { AxiosResponse } from 'axios';
import { AddMeetingIntroductorRequest, ConfirmMeetingRequest, CreateMeetingRequest, Meeting, MeetingPaidRequest, MeetingReviewRequest, MeetingStatusRequest, TimeProposal, UpdateMeetingDetailsRequest } from '../types/meeting';
import { Person } from '../types/person';
import { ChatMessage } from '../types/chat';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/meeting`;

export async function getMeetingById(id: string): Promise<AxiosResponse<Meeting>> {
  return await axios.get(`${API_BASE_URL}/${id}`);
}

export async function getMeetingPersons(id: string): Promise<AxiosResponse<Person[]>> {
  return await axios.get(`${API_BASE_URL}/${id}/persons`);
}

export async function getMeetingTimeProposals(id: string): Promise<AxiosResponse<TimeProposal[]>> {
  return await axios.get(`${API_BASE_URL}/${id}/time-proposals`);
}

export async function getMeetingChat(id: string): Promise<AxiosResponse<ChatMessage[]>> {
  return await axios.get(`${API_BASE_URL}/${id}/chat`);
}

export async function createMeeting(meetingRequest: CreateMeetingRequest): Promise<AxiosResponse<Meeting>> {
  return await axios.post(`${API_BASE_URL}`, meetingRequest);
}

export async function updateMeetingDetails(id: string, updateMeetingDetails: UpdateMeetingDetailsRequest): Promise<AxiosResponse<Meeting>> {
  return await axios.put(`${API_BASE_URL}/${id}/details`, updateMeetingDetails);
}

export async function setMeetingAsPaid(id: string, meetingPaidRequest: MeetingPaidRequest): Promise<AxiosResponse<Meeting>> {
  return await axios.post(`${API_BASE_URL}/${id}/pay`, meetingPaidRequest);
}

export async function newTimeProposals(id: string, newTimeProposals: TimeProposal[]): Promise<AxiosResponse<Meeting>> {
  return await axios.post(`${API_BASE_URL}/${id}/introductor/time-proposals`, {timeProposals: newTimeProposals, anyTime: false});
}

export async function confirmNewTimeProposals(id: string, newTimeProposals: TimeProposal[], anyTime: boolean): Promise<AxiosResponse<Meeting>> {
  return await axios.post(`${API_BASE_URL}/${id}/interessant/time-proposals/confirm`, {timeProposals: newTimeProposals, anyTime: anyTime});
}

export async function confirmTheMeeting(id: string, meetingRequest: ConfirmMeetingRequest): Promise<AxiosResponse<Meeting>> {
  return await axios.post(`${API_BASE_URL}/${id}/introductor/confirm`, meetingRequest);
}

export async function updateMeetingStatusSuccess(id: string, meetingStatusRequest: MeetingStatusRequest): Promise<AxiosResponse<Meeting>> {
  return await axios.put(`${API_BASE_URL}/${id}/success`, meetingStatusRequest); 
}

export async function addMeetingReview(id: string, meetingReviewRequest: MeetingReviewRequest): Promise<AxiosResponse<Meeting>> {
  return await axios.put(`${API_BASE_URL}/${id}/review`, meetingReviewRequest);
}

export async function addIntroductorToTheMeeting(id: string, meetingRequest: AddMeetingIntroductorRequest): Promise<AxiosResponse<Meeting>> {
  return await axios.post(`${API_BASE_URL}/${id}/add-introductor`, meetingRequest);
}