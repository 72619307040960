import { SelectOption } from "../components/atoms/types";

export type Relation = {
    relationType: string,
    strength: number,
    notes?: string
};

export enum RelationTypeEnum {
    SAME_COMPANY = "SAME_COMPANY",
    KNOWS_PERSONAL_UNCONFIRMED = "KNOWS_PERSONAL_UNCONFIRMED",
    KNOWS_PERSONAL_CONFIRMED = "KNOWS_PERSONAL_CONFIRMED",
    FAMILY_RELATED = "FAMILY_RELATED",
    INTRODUCTOR = "INTRODUCTOR",
    OTHER = "OTHER",
    CURRENTLY_WORKING_TOGETHER = "CURRENTLY_WORKING_TOGETHER",
    USED_TO_WORK_TOGETHER = "USED_TO_WORK_TOGETHER",
    FAMILY = "FAMILY",
    PERSONAL_FRIEND = "PERSONAL_FRIEND"
}

const allowedKeys: (keyof typeof RelationTypeEnum)[] = [
    "OTHER",
    "CURRENTLY_WORKING_TOGETHER",
    "USED_TO_WORK_TOGETHER",
    "FAMILY",
    "PERSONAL_FRIEND"
];

export function getRelationTypeString(relationType: RelationTypeEnum): string {
    switch (relationType) {
        case RelationTypeEnum.CURRENTLY_WORKING_TOGETHER:
            return "Currently working together";
        case RelationTypeEnum.USED_TO_WORK_TOGETHER:
            return "Used to work together";
        case RelationTypeEnum.FAMILY:
            return "Family";
        case RelationTypeEnum.PERSONAL_FRIEND:
            return "Personal friend";
        default:
            return "Other";
    }
}


export const RELATION_OPTION_SELECT_LIST: SelectOption[] = allowedKeys.map((allowedKey) => ({
    value: allowedKey,
    label: getRelationTypeString(allowedKey as RelationTypeEnum)
}));

