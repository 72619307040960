import blog1Image from '../../content/blog/newsitems/blog1.png';
import blog2Image from '../../content/blog/newsitems/blog2.png';
import blog3Image from '../../content/blog/newsitems/blog3.png';
import blog4Image from '../../content/blog/newsitems/blog4.png';
import blog5Image from '../../content/blog/newsitems/blog5.png';
import blog6Image from '../../content/blog/newsitems/blog6.png';
import blog7Image from '../../content/blog/newsitems/blog7.png';
import blog8Image from '../../content/blog/newsitems/blog8.png';
import blog9Image from '../../content/blog/newsitems/blog9.png';
import blog10Image from '../../content/blog/newsitems/blog10.png';
import blog12Image from '../../content/blog/newsitems/blog12.png';
import blogGdImage from '../../content/blog/greatdaytoleavemealone/great_day_to_leave_me_alone.png';
import blogssImage from '../../content/blog/summerslowdown/summer_slowdown.png';
import blogmdsImage from '../../content/blog/businessmeetingdeathstars/business_meetings_death_stars.png';
import blogmcmsImage from '../../content/blog/cultivatingmarketingsuccess/CultivatingMarketingSuccessImage.png';
import blogogImage from '../../content/blog/olympicgold/OlympicGoldImage.png';
import blogwtdImage from '../../content/blog/whenturningdown/WhenTurningDownImage.png';

export const NewsBlogItems = [
    {
        title: 'Mondays and Cold Outreach',
        publishDate: '14 August 2024',
        description: `We've all been there. The alarm blares on Monday morning, and suddenly you're longing...`,
        image: blog10Image,
        blogLink: 'https://introductable.com/mondays-cold-outreach'
    },
    {
        title: 'Olympic Gold Or Olympic Last?',
        publishDate: '11 August 2024',
        description: `Carl Lewis won Olympic gold in 1988, earning him the title of the world’s fastest man...`,
        image: blogogImage,
        blogLink: 'https://introductable.com/olympic-gold-or-olympic-last'
    },
    {
        title: 'When Turning Down Payment is the Right Move',
        publishDate: '04 August 2024',
        description: `In the world of business, it's rare to hear of a company turning down payment for services rendered.`,
        image: blogwtdImage,
        blogLink: 'https://introductable.com/when-turning-down-payment'
    },
    {
        title: 'Cultivating Marketing Success: How Do You Till the Business Landscape?',
        publishDate: '31 July 2024',
        description: `In the vast fields of the business world, many have become accustomed to, and dependent on...`,
        image: blogmcmsImage,
        blogLink: 'https://introductable.com/cultivating-marketing-success'
    },
    {
        title: 'Business Meetings and Death Stars',
        publishDate: '25 July 2024',
        description: `Comparing a business networking platform to a galaxy far, far away? Blasphemy!`,
        image: blogmdsImage,
        blogLink: 'https://introductable.com/business-meetings-death-stars'
    },
    {
        title: 'Funnel Vision: Techniques to Amplify Your Lead Generation',
        publishDate: '20 July 2024',
        description: `No one needs to tell you that filling the sales funnel with high-quality leads is more crucial than ever.`,
        image: blog1Image,
        blogLink: 'https://introductable.com/funnel-vision'
    },
    {
        title: '50 Shades of Nay: How Cold Calling Is Somehow Worse Than Tinder',
        publishDate: '17 July 2024',
        description: `Would you rather spend your day getting rejected by strangers over the phone or getting rejected by strangers on your phone?`,
        image: blog12Image,
        blogLink: 'https://introductable.com/fifty-shades-of-nay'
    },
    {
        title: 'Summer Slowdown: How Vacation Season Impacts B2B Sales and Marketing',
        publishDate: '14 July 2024',
        description: `As the temperature rises, many B2B sales and marketing professionals find their outreach efforts cooling off.`,
        image: blogssImage,
        blogLink: 'https://introductable.com/summer-slowdown'
    },
    {
        title: 'Top Ten Leave Me Alone Email Responders',
        publishDate: '09 July 2024',
        description: `As I bask in the glow of my vacation, far from the ping of incoming emails and the buzz of meeting reminders...`,
        image: blogGdImage,
        blogLink: 'https://introductable.com/great-day-to-leave-me-alone'
    },
    {
        title: 'Confession: I\'m a CEO Who Lurks',
        publishDate: '04 July 2024',
        description: `I'm often lurking in marketing subreddits and LinkedIn groups trying to keep my finger on the zeitgeist of marketing...`,
        image: blog2Image,
        blogLink: 'https://introductable.com/confession-ceo-who-lurks'
    },
    {
        title: 'The Tragic Comedy of Cold Outreach; Let’s Commiserate Together',
        publishDate: '27 June 2024',
        description: `Picture this: It's another glorious Monday morning. You've armed yourself with a triple-shot espresso and the unwavering belief that today, yes today, your cold outreach efforts will finally pay off.`,
        image: blog9Image,
        blogLink: 'https://introductable.com/tragic-comedy-of-cold-outreach'
    },
    {
        title: 'Top 10 Reasons Why Cold Outreach Makes Us Want to Crawl Under a Rock',
        publishDate: '22 June 2024',
        description: `Nothing says "I love you" like a copy-pasted message to 10,000 strangers`,
        image: blog8Image,
        blogLink: 'https://introductable.com/ten-reasons-cold-outreach'
    },
    {
        title: 'Use “Relationship Insurance” to Shorten the Sales Cycle',
        publishDate: '10 June 2024',
        description: `One of the best ways to accelerate sales cycles is by injecting a trusted source or referral as early into the process as possible.`,
        image: blog7Image,
        blogLink: 'https://introductable.com/relationship-insurance'
    },
    {
        title: 'The Power of Warm Introductions',
        publishDate: '20 May 2024',
        description: `In today's hyper-connected world, building valuable relationships is pivotal to personal and professional success.`,
        image: blog1Image,
        blogLink: 'https://introductable.com/power-of-warm-introductions'
    },
    {
        title: 'AI at Introductable',
        publishDate: '13 May 2024',
        description: `Introductable leverages artificial intelligence (AI) technology to revolutionize the way professionals connect and network.`,
        image: blog6Image,
        blogLink: 'https://introductable.com/ai-at-introductable'
    },
    {
        title: '“Sometimes to have a revolution, you need to go back to the past.”',
        publishDate: '06 May 2024',
        description: `Networking used to be done in person or by a friend or colleague making an introduction. It worked. Why? Trusted relationships.`,
        image: blog3Image,
        blogLink: 'https://introductable.com/rehumanizing-network'
    },
    {
        title: 'Introductable is great for companies with ongoing demand generation needs!',
        publishDate: '29 April 2024',
        description: `Leveraging a trusted third party through Introducable provides access to a vast network of qualified leads and potential clients.`,
        image: blog4Image,
        blogLink: 'https://introductable.com/lead-generation'
    },
    {
        title: 'The Value of Trusted Introductions',
        publishDate: '22 April 2024',
        description: `At Introductable, we've 're-revolutionized' the way professionals connect and generate demand for their businesses by focusing on the power of trusted relationships`,
        image: blog5Image,
        blogLink: 'https://introductable.com/value-of-trusted-introductions'
    },
    {
        title: 'Explore the Origin and Inspiration Behind Our Company',
        publishDate: '15 April 2024',
        description: `Meet Our Founders`,
        image: blog2Image,
        blogLink: 'https://introductable.com/meet-founders'
    }
];
