import React from 'react';
import './MeetFoundersPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import SingleImageCollage from '../../molecules/collages/SingleImageCollage';
import jeffColor from '../../../content/aboutus/jeff-color-profile.jpeg';
import chemColor from '../../../content/aboutus/chem-color-profile.jpeg';
import FooterAddition from '../../footer/FooterAddition';


const MeetFoundersPage: React.FC = () => {
    return (
        <>
        <Container fluid className='justify-conent-center'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px", paddingBottom: "40px"}}>

            
            <Row className='meet-founders-text-title' style={{ marginTop: '20px' }}>
                <h5 className="main-color">Meet Our Founders</h5>
            </Row>
            <Row className='meet-founders-text-title'>
                <Col><h1>Explore the <span className="main-color">Origin and Inspiration</span> Behind <span className="main-color">Our Company</span></h1></Col>
                   
            </Row>
            <Row className='justify-conent-center align-items-center' style={{ marginTop: '20px' }} >
                <Col xs={12} md={6}>
                    <SingleImageCollage imageSrc={jeffColor} />
                </Col>
                <Col xs={12} md={6}>
                    <Row style={{ marginTop: '20px' }} >
                        <h4 className="main-color">Jeff Buechler,</h4>
                    </Row>
                    <Row>
                        <h5>CEO and Co Founder</h5>
                    </Row>
                    <Row className='meet-founders-text-first'>
                        <p>
                            Meet Jeff Buechler, the inspiration for Introductable. With a passion for connecting people and fostering meaningful relationships, 
                            Jeff, alarmed by the dehumanization of professional connections and by the mass bombardment for connection, embarked on a mission to 
                            revolutionize the way business professionals meet and interact. Armed with a background in sales, 
                            marketing and technology and with an understanding of the desire for real connection, he envisioned a platform that goes beyond traditional networking.
                        </p>
                        <p>
                            His leadership, combined with a genuine belief in the power of introductions, 
                            has shaped Introductable into a platform that enables individuals to forge valuable connections that last a lifetime. 
                            Jeff’s dedication to bringing people together is not just a business philosophy; it’s a personal mission 
                            to make the virtual world a more interconnected and human place.
                        </p>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }} className='meet-founders-second-row-mobile justify-conent-center align-items-center'>
                <Col xs={12} md={6}>
                    <Row className='meet-founders-text-second' style={{ marginTop: '20px' }} >
                        <h4 className="main-color">Przemyslaw (Chem) Roguski,</h4>
                    </Row>
                    <Row className='meet-founders-text-second'>
                        <h5>CTO and Co Founder</h5>
                    </Row>
                    <Row className='meet-founders-text-second'>
                        <p>
                            Meet the tech wizard of Introductable—Przemyslaw Roguski, aka Chem! 
                            With a knack for coding and a passion for pushing the boundaries of technology, 
                            Chem brings his unique blend of expertise in development and AI to the platform. As the driving force behind Introductable's technical prowess, 
                            he's the mastermind that makes Introductable work.
                        </p>
                        <p>
                            Fuelled by Introductable’s mission to bring the person back into networking, Chem's tech-savvy approach always has the person behind the account in mind. 
                            Whether it's crafting intricate algorithms or dreaming up futuristic features, he's always one step ahead, transforming the way professionals 
                            connect and collaborate.
                        </p>
                        <p>
                            Chem's leadership goes beyond just lines of code—he's all about fostering genuine relationships in a digital world. 
                            With his user-centric design philosophy, he ensures that Introductable isn't just a platform—it's a vibrant community 
                            where meaningful connections thrive. Thanks to Chem's technical wizardry, Introductable has become a place for professionals 
                            worldwide to forge valuable connections that last a lifetime.
                        </p>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <SingleImageCollage imageSrc={chemColor} />
                </Col>
            </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default MeetFoundersPage;
