import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { ActionFormProps } from './ActionFormTypes';
import { ConfirmMeetingRequest, TimeProposal } from '../../../../types/meeting';
import { confirmTheMeeting, getMeetingTimeProposals, newTimeProposals } from '../../../../api/meetingApi';
import '../../../../Main.css';
import StyledSelect from '../../../atoms/select/StyledSelect';
import StyledActionButton from '../../../atoms/button/StyledActionButton';
import { DateUtils, generateTimeOptions } from '../../../../utils/DateUtils';
import StyledLabel from '../../../atoms/label/StyledLabel';
import TimeProposalComponent from '../../timeproposal/TimeProposalComponent';
import TimeProposedComponent from '../../timeproposal/TimeProposedComponent';
import StyledCalendarSelect from '../../../atoms/calendar/StyledCalendarSelect';

const PendingDetailsAddedForm: React.FC<ActionFormProps> = ({ meeting, onSubmit }) => {
    const [confirmMeetingRequest, setConfirmMeetingRequest] = useState<ConfirmMeetingRequest>({ meetingDateTime: '' });
    const [requestorTimeProposals, setRequestorTimeProposals] = useState<TimeProposal[]>([]);
    const [timeProposals, setTimeProposals] = useState<TimeProposal[]>([]);
    const [confirmDate, setConfirmDate] = useState<string>('');
    const [confirmTime, setConfirmTime] = useState<string>('');

    useEffect(() => {
        if (meeting.meetingId) {
            getMeetingTimeProposals(meeting.meetingId)
                .then(response => {
                    setRequestorTimeProposals(response.data);  
            })
        }
    }, [meeting, meeting.meetingId]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (meeting.meetingId && DateUtils.isValidDate(confirmDate) && DateUtils.isValidTime(confirmTime)) {
            const isoDate = new Date(`${confirmDate} ${confirmTime}`).toISOString();
            await confirmTheMeeting(meeting.meetingId, { ...confirmMeetingRequest, meetingDateTime: isoDate });
            onSubmit();
        }
    };

    const submitNewTimes = () => {
        if (meeting.meetingId) {
            newTimeProposals(meeting.meetingId, timeProposals).then(
                () => {
                    onSubmit();    
                }
            );
        }
    };

    const handleTimeProposalChange = (newProposals: TimeProposal[]) => {
        setTimeProposals(newProposals);
    };

    return (
        <>
            <Container>
            <Form>
                    <Row>
                        <h4 style={{fontFamily: 'Raleway'}}>Accept or Suggest Meeting Times</h4>
                    </Row>
                    {
                        requestorTimeProposals.length > 0 && (
                        <Row style={{marginTop: '20px'}}>
                            <TimeProposedComponent 
                                readOnlyTimeProposals={requestorTimeProposals} 
                                title={'Requestor Proposed Dates and Times'}                        
                            />
                        </Row>)
                    }
                    <Row style={{marginTop: '20px'}}>
                        <p><span style={{fontWeight: '600', fontSize: '20px'}}>Select</span> one of the proposed meeting date times and enter below</p>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                            <p style={{ marginTop: 'auto' }}>Meeting Date</p>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                            <p style={{ marginBottom: 'auto' }}>
                                Meeting Time (based on Requestor time zone 
                                    <span className='main-color' style={{ fontWeight: '600' }}>
                                        {`${meeting.interessant?.timezone ? ' ' + meeting.interessant?.timezone : ''}`}
                                    </span>)
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '100%'}}>
                            <StyledCalendarSelect 
                                value={confirmDate}
                                onChange={setConfirmDate}                            
                            />
                        </Col>
                        <Col>
                            <StyledSelect 
                                name={'confirmTime'} 
                                value={confirmTime} 
                                onChangeSelect={e => setConfirmTime(e.target.value)} 
                                options={generateTimeOptions()} 
                                placeholder={'Meeting Time'}                
                        /></Col>
                    </Row>
                    {
                        meeting.anyTime && (
                            <Row style={{marginTop: '20px'}}>
                                <p><span className='main-color'>Requestor</span> is available during any of their business hours. (please check time zone)</p>
                            </Row>
                        )
                    }
                    <Row style={{marginTop: '20px'}}>
                        <StyledActionButton onClick={handleSubmit} label={'Set Meeting Date and Time'} /> 
                    </Row>
                </Form>
            {
                !meeting.anyTime && (
                    <>
                        <Row style={{marginTop: '20px'}}>
                            <p>No Times Proposed Work</p>
                        </Row>
                        <Row>
                            <TimeProposalComponent 
                                timeProposalsProp={timeProposals}
                                onTimeProposalsChange={handleTimeProposalChange} 
                                title={<p><span style={{fontWeight: '600', fontSize: '20px'}}>Introductor Proposed Times</span> (the more times you suggest, the better the chance the meeting is set!)</p>}                            
                            />
                        </Row>
                        <Row style={{marginTop: '20px'}}>
                            <StyledActionButton onClick={submitNewTimes} label={'Confirm new time proposals'} /> 
                        </Row>
                    </>
            )}
            <Row style={{marginTop: '20px', marginBottom: '20px'}}>
                <StyledLabel label={'Requestor\'s Meeting Description'} />
                <p>{meeting.description}</p>
            </Row>

            </Container>
        </>
    );
}

export default PendingDetailsAddedForm;